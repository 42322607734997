const validationRules = {
  common: {
    messages: {
      required: 'Обязательно к заполнению'
    }
  },
  title: {
    common: {
      length: {
        min: {
          value: 10
        },
        max: {
          value: 100
        }
      },
      message: 'Длина должна быть от 10 до 100 символов'
    }
  },
  preview: {
    common: {
      length: {
        min: {
          value: 10
        },
        max: {
          value: 500
        }
      },
      message: 'Длина должна быть от 10 до 500 символов'
    }
  },
  description: {
    common: {
      length: {
        min: {
          value: 10
        },
        max: {
          value: 1000
        }
      },
      message: 'Длина должна быть от 10 до 1000 символов'
    }
  },
  upload: {
    common: {
      fileSize: {
        max: {
          value: 10000000,
          label: '10Mb'
        }
      },
      filesLength: {
        min: {
          value: 1,
          message: 'Не менее 1 фото',
        },
        max: {
          value: 10,
          message: 'Не более 10 фото',
        }
      }
    }
  },
  price: {
    min: {
      value: 0,
      message: 'Не менее 1'
    },
    max: {
      value: 1000000000,
      message: 'Не более 1000000000'
    }
  }
}

export { validationRules }
