
import store from '@/store/index'
import { useRoute } from 'vue-router'
import { ref, defineComponent, onMounted, computed, watch } from 'vue'
import ApiService from '@/core/services/ApiService'
import isEmptyObject from '@/core/helpers/isEmptyObject'
import doesObjectsEqual from '@/core/helpers/doesObjectsEqual'
import { ElMessageBox, ElMessage } from 'element-plus'
import { Actions, Mutations } from '@/store/enums/store.enums'
import { ElementAnimateUtil } from '@/assets/ts/_utils/ElementAnimateUtil'
import BasePre from '@/core/components/base/BasePre.vue'
import { rules as newsRules } from '@/core/helpers/validation/validationNewsRules'
import convertToSlug from '@/core/helpers/convertToSlug'
import InterfaceFinalObject from '@/assets/ts/_utils/models/news/InterfaceNewsFinalObject'
import InterfaceRubricObject from '@/assets/ts/_utils/models/news/InterfaceNewsRubricObject'
import InterfaceObjectPhotos from '@/assets/ts/_utils/models/news/InterfaceNewsObjectPhotos'
import InterfaceArticleData from '@/assets/ts/_utils/models/news/InterfaceNewsArticleData'
import InterfaceJsonParsedObject from '@/assets/ts/_utils/models/news/InterfaceNewsJsonParsedObject'
import InterfaceSourceNewsParams from '@/assets/ts/_utils/models/news/InterfaceNewsSourceNewsParams'



export default defineComponent({
  name: 'form',
  components: {
    BasePre,
  },
  setup(props, context) {
    const route = useRoute()

    /*
      Start of Form
    */
    const isFormLoading = ref(false)
    const formRef = ref(null)

    const articleDataInit = ref({})


    const articleData = ref({
      id: null,
      title: '',
      slug: '',
      previewText: '',
      text: '',
      cover: '',
      photos: [],
      photosClone: [],
      newPhotos: [],
      newReadyPhotos: [],
      sources: [],
      sourcePhotosName: '',
      sourceNewsObject: {},
      sourcePhotosObject: {},
      sourceNewsName: '',
      rubricsObjects: [],
      rubricsIds: [],
      rubricsPath: [],
      category: null,
      status: '',
      isOnMain: false,
      isPublished: false,
      createdAt: '',
      'is_on_main': false,
      'is_published': false,
      'created_at': '',
    } as InterfaceArticleData)



    const convertArticleDataForEdit = object => {
      let jsonParsedObject: InterfaceJsonParsedObject = {
        id: null,
        title: '',
        slug: '',
        rubricsObjects: [],
        previewText: '',
        text: '',
        cover: '',
        photos: [],
        category: null,
        sources: [],
        sourcePhotosName: '',
        sourceNewsObject: {},
        status: '',
        'is_on_main': false,
        'is_published': false,
        'created_at': '',
        'preview_text': '',
        'ad_rubrics': [],
        'original_name': '',
        isOnMain: false,
        isPublished: false,
        createdAt: '',
      }

      // Parse the string into the object.
      if (typeof object === 'string') {
        jsonParsedObject = JSON.parse(object)
      } else {
        jsonParsedObject = object
      }
      const finalObject: InterfaceFinalObject = {
        id: 0,
        title: '',
        slug: '',
        category: null,
        rubricsObjects: [],
        rubricsIds: [],
        rubricsPath: [],
        previewText: '',
        text: '',
        cover: '',
        photos: [],
        photosClone: [],
        newPhotos: [],
        newReadyPhotos: [],
        sources: [],
        sourcePhotosName: '',
        sourcePhotosObject: {},
        sourceNewsName: '',
        sourceNewsObject: {},
        status: '',
        isOnMain: false,
        isPublished: false,
        createdAt: '',
      }
      // Just get the value.
      if (jsonParsedObject && jsonParsedObject.id) {
        finalObject.id = jsonParsedObject.id
      }
      // Just get the value.
      finalObject.title = jsonParsedObject.title
      // Just get the value.
      finalObject.slug = jsonParsedObject.slug
      // Just get the value.
      finalObject.rubricsObjects = jsonParsedObject.ad_rubrics
      finalObject.rubricsIds = []
      finalObject.rubricsPath = []
      // Just rename the field.
      finalObject.previewText = jsonParsedObject.preview_text
      // Just get the value.
      finalObject.text = jsonParsedObject.text
      // Just get the value.
      finalObject.category = jsonParsedObject.category.id
      // Just get the value.
      finalObject.cover = jsonParsedObject.cover
      // Get the value and rename properties.
      finalObject.photosClone = jsonParsedObject.photos.map(item => {
        item.name = item.original_name
        item.url = item.path
        return item
      })
      finalObject.photos = jsonParsedObject.photos.map(item => {
        item.name = item.original_name
        item.url = item.path
        return item
      })
      // Just get the value.
      finalObject.sources = jsonParsedObject.sources
      // Get the value and rename properties.
      jsonParsedObject.sources.forEach(item => {
        if (item.type === 'source_image') {
          finalObject.sourcePhotosName = item.name
          finalObject.sourcePhotosObject = item
        } else if (item.type === 'source_news') {
          finalObject.sourceNewsName = item.name
          finalObject.sourceNewsObject = item
        }
      })
      // Just get the value.
      finalObject.status = jsonParsedObject.status
      // Rename the field and change the value.
      finalObject.isOnMain = Boolean(jsonParsedObject.is_on_main)
      // Rename the field and change the value.
      finalObject.isPublished = jsonParsedObject.is_published
      // Rename the field and change the value.
      finalObject.createdAt = jsonParsedObject.created_at

      return finalObject
    }

    /**********************
    * FORM END
    ***********************/



    /**********************
    * UPLOAD START
    ***********************/

    const requestPhotosToServer = async file => {
      const formData = new FormData()
      formData.append('file', file.raw)

      ApiService.setHeader()

      const type = 'news'
      return await ApiService.post(`/admin/file/upload/${type}`, formData)
        .then(response => {
          return response.data
        })
          .catch(() => {
            ElMessage.error('ApiService file upload error')
            console.error('Error :: requestPhotosToServer ::')
          })
    }

    const sendPhotosToServer = async () => {
      articleData.value.newPhotos = []
      for (const item of articleData.value.photosClone) {
        if (item.new) {
          const readyImage: {
            data: InterfaceObjectPhotos
          } = await requestPhotosToServer(item)
          if (!isEmptyObject(readyImage) && !isEmptyObject(readyImage.data)) {
            articleData.value.newPhotos.push(readyImage.data)
          }
        } else {
          articleData.value.newPhotos.push(item)
        }
      }
    }

    // Handle removing files.
    const handleRemoveUpload = file => {
      articleData.value.photosClone = articleData.value.photosClone.filter(
        item => item.id !== file.id
      )
      ElMessage.success('Вы удалили фото')
    }

    const checkFileSize = size => {
      const isLt10Mb = size <= 10000000
      if (isLt10Mb) {
        return true
      } else {
        ElMessage.error('Размер файла не должен быть более 10Mb')
        return false
      }
    }

    const typeStatus = type => {
      const typeStatus = type === 'image/png' || type === 'image/jpeg'
      if (typeStatus) {
        return true
      } else {
        ElMessage.error('Разрешены только PNG или JPG форматы')
        return false
      }
    }

    const validateFile = file => {
      let result = false
      const fileSize = file.size
      const fileType = file.raw.type

      if (checkFileSize(fileSize) && typeStatus(fileType)) {
        result = true
      } else {
        result = false
      }
      return result
    }

    const refsPhotos = ref(null)

    const refsTitle = ref(null)

    const refsRubricsPath = ref(null)

    const refsSourceNewsName = ref(null)

    const refsText = ref(null)

    const refsPreviewText = ref(null)

    /**
     * This function trimmes and saves the array with 5 first elements.
     * @param {array} filelist array of uploaded images.
     * @returns {array} trimmed array: from 0 to 5 items.
     */
    const removeExtraItems = filesArray => {
      const trimmedArray = filesArray.slice(0, 5)
      return trimmedArray
    }

    const photos = ref<InterfaceObjectPhotos[]>([])
    const tempPhotos = ref<InterfaceObjectPhotos[]>([])

    /**
     * This function make the validation for the Upload field
     * by the length of the image array, the image type and the image size.
     * Max image length: 5
     * Types: PNG, JPG
     * Max image size: 3Mb
     * @param {file} file file object
     * @param {array} filelist images array
     */
    const handleChangeUpload = (file, filelist) => {
      // Make a copy.
      photos.value = []
      tempPhotos.value = filelist

      /* Max count validation */
      // If count more than 5 items.
      if (tempPhotos.value.length > 5) {
        tempPhotos.value = removeExtraItems(tempPhotos.value)
      }
      tempPhotos.value.forEach(item => {
        // If the count less or equal 5 images.

        /* Image type and image size validation */
        if (item.raw) {
          if (validateFile(item)) {
            // If validation is OK.
            // Mark the file as NEW for direct server uploading.
            item.new = true
            photos.value.push(item)
          }
        } else {
          photos.value.push(item)
        }
      })
      // After all we need to update the input array and the form array.
      articleData.value.photosClone = photos.value
      refsPhotos.value.uploadFiles = photos.value
    }

    /**********************
    * UPLOAD END
    ***********************/



    /**********************
    * RUBRICS START
    ***********************/

    const rubricValue = ref('')
    const rubricsInitTree = ref([])
    const rubricsInitFlat = ref([] as InterfaceRubricObject[])

    const rubricProps = {
      multiple: true,
      checkStrictly: true,
    }

    /**
     * Converts array of rubric names of the single last rubric name.
     * @param {Array}.
     */
    const convertRubricsName = value => {
      if (value.length) {
        articleData.value.rubricsIds = []
        articleData.value.rubricsObjects = []
        value.forEach(item => {
          // Get the last rubric in the array.
          const code = item[item.length - 1]
          // Walks through the flat list and find the coincidence.
          if (rubricsInitFlat.value.length) {
            const selectedRubricObject: InterfaceRubricObject = rubricsInitFlat.value.find(
              subItem => subItem.code === code
            )
            if (!isEmptyObject(selectedRubricObject)) {
              articleData.value.rubricsIds.push(selectedRubricObject.id)
              articleData.value.rubricsObjects.push(selectedRubricObject)
            }
          }
        })
      }
    }

    /**
     * Convert the object to the string path (rubric path).
     * @param {Object} Rubric object.
     * @returns {String}.
     */
    const convertRubricsObjectToPath = object => {
      const pathArray = []
      let item2, item3, item4
      const item1 = object
      pathArray.push(item1.code)
      if (item1 && item1.parent_id) {
        item2 = rubricsInitFlat.value.find(item => item.id === item1.parent_id)
        pathArray.push(item2.code)
      }
      if (item2 && item2.parent_id) {
        item3 = rubricsInitFlat.value.find(item => item.id === item2.parent_id)
        pathArray.push(item3.code)
      }
      if (item3 && item3.parent_id) {
        item4 = rubricsInitFlat.value.find(item => item.id === item3.parent_id)
        pathArray.push(item4.code)
      }
      return pathArray.reverse()
    }

    /**
     * Makes request for rubrics array.
     */
    const rubricsRequest = async () => {
      // Get flat list.
      return await store.dispatch(
        Actions.GET_RUBRICS_LIST,
        {
          limit: 10,
        }
      )
    }

    /**
     * Update the state of the list of rubrics.
     * @param {Array} Array of rubrics.
     */
    const updateStateRubricsList = async data => {
      store.commit(Mutations.SET_RUBRICS_LIST_MUTATION, data)
    }

    /**
     * Converts the flat list to the tree.
     * @param {Array} Array of rubrics.
     */
    const convertRubricsListToTree = async data => {
      return await store.dispatch(Actions.SET_RUBRICS_TREE, data)
    }


    /**
     * Getting rubrics.
     */
    const getRubrics = async () => {
      let rubrucsList: InterfaceRubricObject[] = []

      rubrucsList = await rubricsRequest()

      if (rubrucsList.length) {
        rubricsInitFlat.value = rubrucsList

        await updateStateRubricsList(rubrucsList)
        const treeResult = await convertRubricsListToTree(rubrucsList)

        rubricsInitTree.value = treeResult

        // Update objects(they doesn't have 'parent_id').
        articleData.value.rubricsObjects = articleData.value.rubricsObjects.map(
          objectItem => {
            return rubricsInitFlat.value.find(
              flatItem => {
                return flatItem.id === objectItem.id
              }
            )
          }
        )

        articleData.value.rubricsObjects.forEach(item => {
          const resItem: {} = convertRubricsObjectToPath(item)
          articleData.value.rubricsPath.push(resItem)
          articleData.value.rubricsIds.push(item.id)
        })
      }
    }

    /**
     * Watching for the Rubrics input value.
     */
    watch(
      () => articleData.value.rubricsPath,
      currentValue => {
        convertRubricsName(currentValue)
      }
    )

    /**********************
    * RUBRICS END
    ***********************/



    /**********************
    * NEWS SOURCES START
    ***********************/

    const articleNewsSources = ref([])
    const articlePhotosSources = ref([])
    const responseSources = ref([])

    const requestNewsSource = async (type, phrase, limit) => {
      const params: InterfaceSourceNewsParams = {
        'filter[type]': type,
        'filter[name]': phrase,
        'filter[status]': 'confirmed',
      }
      if (limit) {
        params.limit = limit
      }
      return await ApiService.query('/admin/news-sources', { params })
        .then(response => {
          if (type === 'source_news') {
            articleNewsSources.value = response.data.items
          } else if (type === 'source_image') {
            articlePhotosSources.value = response.data.items
          }
          return response.data.items
        })
        .catch(() => {
          ElMessage.warning({
            type: 'warning',
            message: 'Что-то не так с запросом источников новостей',
          })
        })
    }

    const sourcesSuggest = async (type, maxLength, queryString, cb) => {
      if (queryString && queryString.length !== 0) {
        const responseSources = await requestNewsSource(
          type,
          queryString,
          maxLength,
        )

        if (responseSources && responseSources.length) {
          const results = responseSources.map(item => {
            item['value'] = item.name
            return item
          })

          cb(results)
        } else {
          cb([])
        }
      }
    }


    const suggestNewsSources = (queryString, cb) => {
      return sourcesSuggest('source_news', 20, queryString, cb)
    }


    /**
     * Clear the selected source object.
     * @params {string}
     */
    const clearNewsSource = () => {
      articleData.value.sourceNewsObject = null
    }


    /**
     * The new source creating.
     * Convert the new source name to the ID number.
     * @params {string} The selected source type.
     * @params {string} The selected source name.
     * @return {number} Source ID.
     */
    const createSource = (type, name) => {
      const params = {
        type: type,
        name: name,
        slug: convertToSlug(name),
        status: 'confirmed',
        sort: 500
      }

      return ApiService.post('/admin/news-sources', { ...params })
        .then(response => response.data.data.id)
          .catch(() => {
            console.error('> Something wrong with the source creating')
            return null
          })
    }


    /**
     * Convert the selected source name to the object.
     * @params {string}
     */
    const typeNewsSource = sourceString => {
      clearNewsSource()
      articleData.value.sourceNewsName = sourceString
    }

    /**********************
    * NEWS SOURCES END
    ***********************/



    /**********************
    * PHOTO SOURCES START
    ***********************/

    const suggestPhotoSources = async (queryString, cb) => {
      return sourcesSuggest('source_image', 20, queryString, cb)
    }


    /**
     * Clear the selected source object.
     * @params {string}
     */
    const clearPhotoSource = () => {
      articleData.value.sourcePhotosObject = null
    }


    /**
     * Convert the selected source name to the object.
     * @params {string}
     */
    const typePhotoSource = sourceString => {
      clearPhotoSource()
      articleData.value.sourcePhotosName = sourceString
    }

    /**********************
    * PHOTO SOURCES END
    ***********************/



    /**********************
    * CATEGORIES START
    ***********************/

    interface InterfaceCategoryitem {
      id: number
      name: string
      slug: string
    }

    const categoriesArray = ref([] as InterfaceCategoryitem[])

    // Call the action for the categories request.
    const requestCategories = async () => {
      categoriesArray.value = await store.dispatch(Actions.GET_CATEGORIES_LIST)
    }

    /**********************
    * CATEGORIES END
    ***********************/



    const convertArticleDataForSending = async object => {
      const finalObject = { ...object.value }
      finalObject['title'] = object.title
      finalObject['slug'] = object.slug
      finalObject['status'] = object.status
      finalObject['photos'] = []
      finalObject['photos'] = object.newPhotos.map(item => item.id)
      delete finalObject.cover
      finalObject['created_at'] = object.createdAt
      delete finalObject.createdAt
      finalObject['preview_text'] = object.previewText
      finalObject['text'] = object.text
      delete finalObject.previewText
      finalObject['ad_rubrics'] = object.rubricsObjects.map(item => item.id)
      delete finalObject.rubricsObjects
      finalObject['is_on_main'] = object.isOnMain
      delete finalObject.isOnMain
      finalObject['is_published'] = Boolean(object.isPublished)
      delete finalObject.isPublished
      finalObject['sources'] = []
      finalObject['category_id'] = object.category

      if (object.sourcePhotosObject && object.sourcePhotosObject.id) {
        finalObject['sources'].push(object.sourcePhotosObject.id)
      } else if (object.sourcePhotosName && object.sourcePhotosName.length) {
        const newSourceId = await createSource('source_image', object.sourcePhotosName)
        if (newSourceId) {
          finalObject['sources'].push(newSourceId)
        }
      }

      if (object.sourceNewsObject && object.sourceNewsObject.id) {
        finalObject['sources'].push(object.sourceNewsObject.id)
      } else if (object.sourceNewsName && object.sourceNewsName.length) {
        const newSourceId = await createSource('source_news', object.sourceNewsName)
        if (newSourceId) {
          finalObject['sources'].push(newSourceId)
        }
      }


      delete finalObject.sourceNewsObject
      delete finalObject.sourcePhotosObject
      delete finalObject.sourcePhotosName
      delete finalObject.sourceNewsName
      delete finalObject.rubricsPath
      delete finalObject.rubricsIds
      delete finalObject.newPhotos
      delete finalObject.newReadyPhotos
      delete finalObject.id
      delete finalObject.created_at

      return finalObject
    }



    const requestToEditSingleNews = object => {
      return ApiService.post(`/admin/news/${route.params.id}`, object)
        .then(response => {
          isFormLoading.value = false
          ElMessage({
            type: 'success',
            message: 'Новость сохранена',
          })
          return response.data.result
        })
        .catch(() => {
          isFormLoading.value = false
          ElMessage({
            type: 'info',
            message: 'Ошибка при сохранении',
          })
          console.error('requestToEditSingleNews :: catch ::')
        })
    }

    const requestToCreateSingleNews = object => {
      return ApiService.post(`/admin/news/`, object)
        .then(response => {
          isFormLoading.value = false
          ElMessage({
            type: 'success',
            message: 'Новость создана',
          })
          return response.data.result
        })
        .catch(() => {
          isFormLoading.value = false
          ElMessage({
            type: 'info',
            message: 'Ошибка при создании',
          })
          console.error('requestToEditSingleNews :: catch ::')
        })
    }

    /**
    * Scroll the window to the invalid field.
    */
    const scrollToInvalid = element => {
      const refsName = `refs${element.charAt(0).toUpperCase()}${element.slice(1)}`
      const refsEl = eval(refsName).value.$el
      ElementAnimateUtil.scrollTo(refsEl, 100, 500)
    }

    // Sync the form data with the parent.
    const moveDataToParent = data => {
      context.emit('changeCreateResult', data)
    }


    /**
     * The form submitting.
     * Validating the form.
     * Preparing data for submit.
     * Request the data to the server.
     */
    const submitForm = () => {
      /*
        Detect form validation.
      */
      formRef.value.validate(async (valid, fields) => {
        if (valid) {
          isFormLoading.value = true
          /*
            Upload new photos.
          */
          await sendPhotosToServer()
          /*
            Convert data for the sending format.
          */
          const dataForSend = await convertArticleDataForSending(articleData.value)
          if (route.params.entry || route.params.id) {
            /*
              Sending data to server for editing.
            */
            requestToEditSingleNews(dataForSend)
          } else {
            dataForSend['status'] = 'not_verified'
            /*
              Sending data to server for creating.
            */
            const result: Promise<boolean> = await requestToCreateSingleNews(
              dataForSend
            )
            moveDataToParent(result)
          }
        } else {
          scrollToInvalid(Object.keys(fields)[0])
        }
      })
    }


    // Reset the form.
    const resetForm = () => {
      ElMessageBox.confirm(
        `Вы действительно хотите <strong>очистить</strong> форму?`,
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Очистить',
          dangerouslyUseHTMLString: true,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              setTimeout(() => {
                formRef.value.resetFields()
                done()
                setTimeout(() => {
                  instance.confirmButtonLoading = false
                }, 300)
              }, 2000)
            } else {
              done()
            }
          },
        }
      )
        .then(() => {
          ElMessage({
            type: 'success',
            message: 'Форма сброшена',
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: 'Отмена сброса',
          })
        })
    }

    const requestToGetSingleNews = () => {
      const params = {}
      return ApiService.query(`/admin/news/${route.params.id}`, { params })
        .then(response => {
          return response.data
        })
        .catch(() => {
          ElMessage({
            type: 'warning',
            message: 'Ошибка запроса новости',
          })
        })
    }

    /**
     * Request the single news on the page loading.
     */
    const getNewsOnLoad = async () => {
      if (
        !route.params.entry &&
        !isEmptyObject(route.params.entry) &&
        route.params.id
      ) {
        const singleNews = await requestToGetSingleNews()
        articleData.value = convertArticleDataForEdit(singleNews)
      } else if (route && route.params && route.params.entry) {
        articleData.value = convertArticleDataForEdit(route.params.entry)
      }
      articleDataInit.value = Object.assign({}, articleData.value)

      getRubrics()

      requestCategories()
    }

    /*
      End of the FORM ACTIONS
    */

    onMounted(() => {
      getNewsOnLoad()
    })

    // Detection whether the form has been changed.
    const doesFormHasBeenChanged = computed(() => {
      return doesObjectsEqual(articleDataInit, articleData)
    })

    return {
      // CATEGORY
      categoriesArray,
      // REFS
      refsTitle,
      refsText,
      refsPreviewText,
      refsSourceNewsName,
      refsPhotos,
      refsRubricsPath,
      // RUBRICS
      rubricProps,
      rubricValue,
      rubricsInitFlat,
      rubricsInitTree,
      // requestRubrics,
      convertRubricsName,
      convertRubricsObjectToPath,
      // FILE UPLOAD
      handleChangeUpload,
      handleRemoveUpload,
      requestPhotosToServer,
      // FORM
      newsRules,
      formRef,
      articleData,
      isFormLoading,
      articleDataInit,
      articleNewsSources,
      doesFormHasBeenChanged,
      moveDataToParent,
      resetForm,
      submitForm,
      convertArticleDataForEdit,
      // NEWS SOURCES
      responseSources,
      typeNewsSource,
      // selectSourceNews,
      suggestNewsSources,
      // PHOTO SOURCES
      typePhotoSource,
      // selectSourcePhoto,
      suggestPhotoSources,
    }
  },
})
