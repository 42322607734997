import { validationRules } from '@/core/helpers/validation/validationRules'
import { ElMessage } from 'element-plus'


/**
 * Rubrics validation.
 */
const validateRubrics = (rule, value, callback) => {
  if (value.length === 0) {
    return callback(new Error(validationRules.common.messages.required))
  } else if (value.length > 3) {
    ElMessage.warning({
      type: 'warning',
      message: 'Не более 3 рубрик',
    })
    return callback(new Error('Не более 3 рубрик'))
  } else {
    return true;
  }
}


// Form validation rules
const rules = {
  title: [
    {
      required: true,
      message: validationRules.common.messages.required,
      trigger: 'blur',
    },
    {
      min: validationRules.title.common.length.min.value,
      max: validationRules.title.common.length.max.value,
      message: validationRules.title.common.message,
      trigger: 'blur',
    },
  ],
  previewText: [
    {
      required: true,
      message: validationRules.common.messages.required,
      trigger: 'blur',
    },
    {
      min: validationRules.preview.common.length.min.value,
      max: validationRules.preview.common.length.max.value,
      message: validationRules.preview.common.message,
      trigger: 'blur',
    },
  ],
  text: [
    {
      required: true,
      message: validationRules.common.messages.required,
      trigger: 'blur',
    },
    {
      min: validationRules.description.common.length.min,
      max: validationRules.description.common.length.max,
      message: validationRules.common.messages.required,
      trigger: 'blur',
    },
  ],
  category: [
    {
      required: true,
      message: validationRules.common.messages.required,
      trigger: 'blur',
    },
  ],
  articleType: [
    {
      required: true,
      message: validationRules.common.messages.required,
      trigger: 'blur',
    },
  ],
  rubricsPath: [
    {
      required: true,
      validator: validateRubrics,
      trigger: 'change',
    },
  ],
  sourceNewsName: [
    {
      required: true,
      trigger: 'change',
      message: validationRules.common.messages.required,
    },
  ],
  sourcePhotosName: [
    {
      required: true,
      trigger: 'change',
      message: validationRules.common.messages.required,
    },
  ],
}

export { rules }