// Check whether both objects are equal.
function doesObjectsEqual(object1, object2) {
  // console.log('> eq1', JSON.stringify(object1))
  // console.log(' ')
  // console.log('> eq2', JSON.stringify(object2))
  // console.log(' ')
  // console.log('> eq1 vs eq2', JSON.stringify(object1) === JSON.stringify(object2))
  return JSON.stringify(object1) === JSON.stringify(object2)
}

export default doesObjectsEqual
